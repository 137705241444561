<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="topsubmenu auto">
        <a-radio-group default-value="5" size="small" @change="changeSubmenu" button-style="solid">
          <a-radio-button value="1">
            系统概况
          </a-radio-button>
          <a-radio-button value="2">
            人脸识别
          </a-radio-button>
          <a-radio-button value="3">
            用户登录
          </a-radio-button>
          <a-radio-button value="4">
            访问量
          </a-radio-button>
          <a-radio-button value="5">
            登录IP
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>登录IP情况统计</b></span>
        </div>
        <div class="fr">
          <custom-date-picker v-model="date" @getDate="getDate" @getMode="getMode"></custom-date-picker>
        </div>
      </div>
      <div class="department-content auto">
        <div class="box auto">
          <div class="box-title auto">
            <div class="fl">登录IP失败次数（Top 10）</div>
          </div>
          <div class="box-con auto" style="width:100%;height:300px;">
            <a-spin :spinning="chartLoading">
              <echarts-base :data="chartData" :height="300"></echarts-base>
            </a-spin>
          </div>
        </div>
        <div class="box box2 auto">
          <div class="box-title auto">
            <div class="fl">用户登录失败次数</div>
          </div>
          <div class="box-con auto no-bor">
            <div class="m-t-10">
              <a-spin :spinning="tableLoading">
                <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" @change="changeTable">
                  <span slot="action" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
                </a-table>
              </a-spin>
            </div>
            <div class="m-t-10">
              <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
import { Data as DataController } from '@/controller'
export default {
  data () {
    return {
      date: [],
      chartData: '',
      chartFilter: {
        mode: 'week',
        startDate: '',
        endDate: ''
      },
      chartLoading: false,
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          key: 'action'
        },
        {
          title: '登录IP',
          dataIndex: 'loginip',
          key: 'loginip'
        },
        {
          title: '失败次数',
          dataIndex: 'failcount',
          scopedSlots: { customRender: 'failcount' },
          key: 'failcount',
          sorter: true,
          // sortOrder: 'descend',
          sortDirections: ['ascend', 'descend']
        }
      ],
      tabledata: [],
      tableLoading: false,
      filter: {
        order: 'desc',
        current_page: 1,
        page_size: 10
      },
      total: 0
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/analysis/index');
          break;
        case 2:
          this.$router.push('/system/analysis/face');
          break;
        case 3:
          this.$router.push('/system/analysis/login');
          break;
        case 4:
          this.$router.push('/system/analysis/active');
          break;
        case 5:
          this.$router.push('/system/analysis/ip');
          break;
        default:
          break;
      }
    },
    changeTable (pagination, filters, sorter, { currentDataSource }) {
      // console.log(sorter)
      this.filter['current_page'] = 1
      if (sorter.order) {
        var word = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.filter['order'] = word
      } else {
        this.filter['order'] = ''
      }
      this.getLoginSummaryFailed()
    },
    getMode (mode) {
      this.chartFilter.mode = mode
    },
    getDate (date) {
      // console.log('date', date)
      this.chartFilter.startDate = date[0]
      this.chartFilter.endDate = date[1]
      this.getLoginSummary()
      this.getLoginSummaryFailed()
    },
    async getLoginSummary () {
      this.chartLoading = true
      let res = await System.getIpaccessSummary(this.chartFilter)
      this.chartLoading = false
      if (!res['code']) {
        this.chartData = DataController.System.Bar4(res['data'].length ? res['data'] : '')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getLoginSummaryFailed()
    },
    async getLoginSummaryFailed () {
      this.tableLoading = true
      let form = Object.assign(this.chartFilter, this.filter)
      let res = await System.getIpaccessSummaryFailed(form)
      this.tableLoading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    // this.getLoginSummary()
    // this.getLoginSummaryFailed()
  },
  mounted () {
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 0px 0 20px;
  color: rgba(0,0,0,0.85);
}

.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}
</style>
